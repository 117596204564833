import { createRouter, createWebHistory } from "vue-router";
import FormView from "../views/FormView.vue";
import ThankYou from "../views/ThankYou.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: FormView,
    props: (route) => ({ collegeId: route.query.collegeId }),
  },
  {
    path: "/thank-you",
    name: "thankYou",
    component: ThankYou,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
