import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';

const { apiHost } = window.env ? window.env : { apiHost: 'stage-api.newmanministry.com' };

const http = axios.create({
  baseURL: `https://${apiHost}/rest/`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
});

http.interceptors.response.use(
  response => {
    switch (response.status) {
      case 200: // OK
      case 201: // Created
      case 204: // No Content
      default:
        return Promise.resolve(response);
    }
  },
  error => {
    if (!error.response) return Promise.reject(error);
    switch (error.response.status) {
      case 401: // Not Authorized
        return Promise.reject(error);
      default:
        return Promise.reject(error);
    }
  },
);

const app = createApp(App);
app.use(router).mount('#app');

export default http;