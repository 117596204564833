<template>
  <header v-if="!inIframe">
    <a href="https://www.newmanministry.com/" target="_blank">
      <img
        alt="Newman Ministry Logo"
        src="./assets/images/newman_ministry_logo.png"
      />
    </a>
  </header>
  <main :class="{ 'in-iframe': inIframe }">
    <router-view />
  </main>
</template>

<script>
// import { ref } from 'vue'

export default {
  components: {},
  props: [],
  setup() {
    const inIframe = window.self !== window.top;

    return {
      inIframe,
    };
  },
};
</script>

<style lang="scss">
@import "./assets/scss/_variables.scss";
@import "./assets/scss/core.scss";
</style>
