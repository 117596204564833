<template>
  <div @click="formWrapperClick" :class="{ submitting: formIsSubmitting }">
    <div class="headings">
      <div class="container">
        <h1>Student Sign Up</h1>
        <h2>Get Connected to Campus Ministry today!</h2>
      </div>
    </div>

    <div class="form-wrapper">
      <Form
        @submit="sendForm"
        :validation-schema="validationRules"
        v-slot="{ errors }"
      >
        <div class="accent-bg">
          <div class="container">
            <div class="form-inputs">
              <div class="form-row">
                <div class="form-field" :class="{ error: errors.firstName }">
                  <label>First Name <span>*</span></label>
                  <Field name="firstName" type="text" autocomplete="off" />
                  <div class="error-msg">First Name is required.</div>
                </div>
                <div class="form-field" :class="{ error: errors.lastName }">
                  <label>Last Name <span>*</span></label>
                  <Field name="lastName" type="text" autocomplete="off" />
                  <div class="error-msg">Last Name is required.</div>
                </div>
              </div>

              <div class="form-row">
                <div class="form-field" :class="{ error: errors.email }">
                  <label>Email Address <span>*</span></label>
                  <Field
                    name="email"
                    type="email"
                    placeholder="example@domain.com"
                    autocomplete="off"
                  />
                  <div class="error-msg">
                    Please enter a valid email address.
                  </div>
                </div>
                <div class="form-field" :class="{ error: errors.phone }">
                  <label>Phone Number <span>*</span></label>
                  <Field
                    name="phone"
                    type="tel"
                    placeholder="(111) 111 - 1111"
                    autocomplete="off"
                    v-model="phoneInputStudent"
                  />
                  <div class="error-msg">
                    Please enter a 10 digit phone number.
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="form-field">
                  <label class="icon-label">
                    <img src="@/assets/images/instagram_icon.svg" alt="Instagram icon" class="icon"/>
                    Instagram
                  </label>
                  <Field
                    name="instagram"
                    type="text"
                    placeholder="https://www.instagram.com/username"
                    autocomplete="off"
                  />
                </div>
                <div class="form-field">
                  <label class="icon-label">
                    <img src="@/assets/images/snapchat_icon.svg" alt="snapchat icon" class="icon"/>
                    Snapchat
                  </label>
                  <Field
                    name="snapchat"
                    type="text"
                    placeholder="http://www.snapchat.com/add/username"
                    autocomplete="off"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-field">
                  <label>Address</label>
                  <Field name="address" type="text" autocomplete="off" />
                </div>
                <div class="form-field">
                  <label>Apartment, suite, etc.</label>
                  <Field name="address2" type="text" autocomplete="off" />
                </div>
              </div>
              <div class="form-row">
                <div class="form-field">
                  <label>City</label>
                  <Field name="city" type="text" autocomplete="off" />
                </div>
                <div class="form-row form-field">
                  <div class="form-field">
                    <label>State</label>
                    <Field name="state" as="select" class="state-select">
                      <option disabled hidden value="">Select State</option>
                      <option v-for="state in unitedStatesList" :key="state" :value="state" >
                        {{ state }}
                      </option>
                    </Field>
                  </div>
                  <div class="form-field" :class="{ error: errors.postalCode }">
                    <label>Postal Code</label>
                    <Field name="postalCode" type="text" autocomplete="off" placeholder="11111"/>
                    <div class="error-msg">Please enter a 5 or 9 digit postal code.</div>
                  </div>
                </div>

              </div>

              <div class="form-row high-school">
                <div class="form-field" :class="{ error: errors.highSchool }">
                  <label>High School or Parish</label>
                  <div class="ac-input-wrapper" @click.stop="">
                    <Field
                      name="highSchool"
                      type="text"
                      placeholder="Enter the city where your high school or parish is located..."
                      autocomplete="off"
                      @keyup="autocomplete('highSchool')"
                      @focus="acFocus('highSchool', ['college'])"
                      v-model="acSettings.highSchool.searchQuery"
                    />
                    <div
                      class="autocomplete"
                      v-if="acSettings.highSchool.itemsList.length"
                      :style="{
                        overflowY:
                          acSettings.highSchool.itemsList.length > 5
                            ? 'scroll'
                            : 'auto',
                      }"
                    >
                      <ul class="results">
                        <li
                          v-for="item in acSettings.highSchool.itemsList"
                          :key="item.id"
                          @click="chooseItem(item, 'highSchool')"
                        >
                          <div class="primary-line">{{ item.name }}</div>
                          <div
                            class="secondary-line"
                            v-if="secondaryLineValidation(item.addresses)"
                          >
                            <span
                              >{{ item.addresses[0].city }},
                              {{ item.addresses[0].stateName }}</span
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="error-msg">
                    High School or Parish is required.
                  </div>
                </div>
                <div class="form-field" :class="{ error: errors.gradYear }">
                  <label>High School Graduation Year <span>*</span></label>
                  <Field
                    name="gradYear"
                    type="number"
                    :placeholder="currentYear"
                    autocomplete="off"
                    :value="currentYear"
                  />
                  <div class="error-msg">Please enter a 4 digit year.</div>
                </div>
              </div>

              <div class="form-row full">
                <div class="form-field" :class="{ error: errors.college }">
                  <label>College Attending or Military Branch Joining <span>*</span></label>
                  <div class="ac-input-wrapper" @click.stop="">
                    <Field
                      name="college"
                      type="text"
                      placeholder="Type name of college, city, state, or military branch..."
                      autocomplete="off"
                      @keyup="autocomplete('college')"
                      @focus="acFocus('college', ['highSchool'])"
                      v-model="acSettings.college.searchQuery"
                    />
                    <div class="error-msg">
                      Please choose a college from the list. If you don't see
                      your college, please
                      <a
                        href="https://www.newmanministry.com/contact"
                        target="_blank"
                        >contact Cassie here</a
                      >.
                    </div>
                    <div
                      class="autocomplete"
                      v-if="acSettings.college.itemsList.length"
                      :style="{
                        overflowY:
                          acSettings.college.itemsList.length > 5
                            ? 'scroll'
                            : 'auto',
                      }"
                    >
                      <ul class="results">
                        <li
                          v-for="item in acSettings.college.itemsList"
                          :key="item.id"
                          @click="chooseItem(item, 'college')"
                        >
                          <div class="primary-line">{{ item.name }}</div>
                          <div
                            class="secondary-line"
                            v-if="secondaryLineValidation(item.addresses)"
                          >
                            <span
                              >{{ item.addresses[0].city }},
                              {{ item.addresses[0].stateName }}</span
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row full">
                <div class="form-field" :class="{ error: errors.hearAboutUs }">
                  <label>Where did you hear about us? <span>*</span></label>
                  <div class="ac-input-wrapper" @click.stop="">
                    <Field
                      name="hearAboutUs"
                      type="text"
                      placeholder=""
                      autocomplete="off"
                    />
                    <div class="error-msg">
                      This field is required.
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row full radio">
                <div class="form-field">
                  <label
                    >Are you the Parent or Legal Guardian of this
                    Student?</label
                  >
                  <div class="pretty-radio-options">
                    <div
                      class="option"
                      :class="{ selected: !isParent }"
                      @click="updateParentVisbility(false)"
                    >
                      <div class="circle"></div>
                      <label>No</label>
                    </div>
                    <div
                      class="option"
                      :class="{ selected: isParent }"
                      @click="updateParentVisbility(true)"
                    >
                      <div class="circle"></div>
                      <label>Yes</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="parent-fields" v-if="isParent">
              <div class="form-inputs">
                <div class="form-row">
                  <div
                    class="form-field"
                    :class="{ error: errors.parentFirstName }"
                  >
                    <label>Parent First Name <span>*</span></label>
                    <Field
                      name="parentFirstName"
                      type="text"
                      autocomplete="off"
                    />
                    <div class="error-msg">First Name is required.</div>
                  </div>
                  <div
                    class="form-field"
                    :class="{ error: errors.parentLastName }"
                  >
                    <label>Parent Last Name <span>*</span></label>
                    <Field
                      name="parentLastName"
                      type="text"
                      autocomplete="off"
                    />
                    <div class="error-msg">Last Name is required.</div>
                  </div>
                </div>

                <div class="form-row">
                  <div
                    class="form-field"
                    :class="{ error: errors.parentEmail }"
                  >
                    <label>Parent Email Address <span>*</span></label>
                    <Field
                      name="parentEmail"
                      type="email"
                      placeholder="example@domain.com"
                      autocomplete="off"
                    />
                    <div class="error-msg">
                      Please enter a valid email address.
                    </div>
                  </div>
                  <div
                    class="form-field"
                    :class="{ error: errors.parentPhone }"
                  >
                    <label>Parent Phone Number</label>
                    <Field
                      name="parentPhone"
                      type="tel"
                      placeholder="(111) 111 - 1111"
                      autocomplete="off"
                      v-model="phoneInputParent"
                    />
                    <div class="error-msg">
                      Please enter a 10 digit phone number.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-submit">
          <div class="container">
            <button type="submit">Sign Up</button>
            <p class="note"><span>*</span> indicates required field</p>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { defineRule, Form, Field } from "vee-validate";
import { required, email, digits, max } from "@vee-validate/rules";
import http from "@/main";

export default {
  name: "FormView",
  props: {
    collegeId: String,
  },
  components: {
    Form,
    Field,
  },
  setup(props) {
    const router = useRouter();

    const formIsSubmitting = ref(false);

    const unitedStatesList = [
      'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'ID',
      'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS',
      'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK',
      'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV',
      'WI', 'WY'
    ];

    const acSettings = ref({
      searchFrequency: 300,
      searchTimer: null,
      highSchool: {
        itemsList: [],
        searchQuery: "",
        itemSelected: null,
      },
      college: {
        itemsList: [],
        searchQuery: "",
        itemSelected: null,
      },
    });

    const generateApiSearchQuery = (searchString, fields) => {
      let query = ``;
      let searchStringArr = searchString.split(` `).map((el) => {
        return el.toLowerCase();
      });
      searchStringArr.forEach((word, index) => {
        query += `(`;
        fields.forEach((field, index2) => {
          query += `${field}:"${word}"`;
          // START: synonyms
          if (word === "st." || word === "st") {
            query += ` OR ${field}:"saint"`;
          }
          if (word === "saint") {
            query += ` OR ${field}:"st."`;
          }
          // END: synonyms
          if (index2 + 1 < fields.length) {
            query += ` OR `;
          }
        });
        query += `)`;
        if (index + 1 < searchStringArr.length) {
          query += ` AND `;
        }
      });
      return query;
    };

    const autocomplete = (scope) => {
      acSettings.value[scope].itemSelected = null;
      acSettings.value.searchTimer &&
        clearInterval(acSettings.value.searchTimer);
      if (acSettings.value[scope].searchQuery.length) {
        const timer = setTimeout(async () => {
          try {
            let apiEndpoint = "";
            let shared_query = generateApiSearchQuery(
              acSettings.value[scope].searchQuery,
              ["name", "city", "state"]
            );
            if (scope === "highSchool") {
              apiEndpoint = `entity?search=(entityType:HIGH_SCHOOL OR entityType:PARISH) AND (${shared_query})`;
            } else if (scope === "college") {
              apiEndpoint = `college?search=${shared_query}`;
            }
            const res = await http.get(apiEndpoint);
            acSettings.value[scope].itemsList = res.data.results;
          } catch (error) {
            console.log("error", error);
          }
        }, acSettings.value.searchFrequency);
        acSettings.value.searchTimer = timer;
      } else {
        acSettings.value[scope].itemsList = [];
      }
    };

    const chooseItem = (item, scope) => {
      acSettings.value[scope].searchQuery = item.name;
      acSettings.value[scope].itemSelected = item;
      acSettings.value[scope].itemsList = [];
    };

    // Pre-populate college from URL
    if (props.collegeId && props.collegeId.length && !isNaN(props.collegeId)) {
      http
        .get(`college/${props.collegeId}`)
        .then((response) => {
          if ("data" in response && typeof response.data == "object") {
            chooseItem(response.data, "college");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    const secondaryLineValidation = (addresses) => {
      return (
        addresses &&
        addresses.length &&
        addresses[0].city &&
        addresses[0].stateName
      );
    };

    const acFocus = (scope, acToClose) => {
      acToClose.forEach((ac) => {
        closeAutocomplete(ac);
      });
      autocomplete(scope);
    };

    const isParent = ref(false);

    const phoneValidation = (value, scope) => {
      if (value && value.length) {
        let charArr = value.split("");
        let c = charArr.filter((char) => {
          return char !== " " && !isNaN(char);
        });
        if (c.length === 10 || c.length === 11) {
          let formatted_number = "";
          if (c.length === 10) {
            formatted_number = `(${c[0]}${c[1]}${c[2]}) ${c[3]}${c[4]}${c[5]} - ${c[6]}${c[7]}${c[8]}${c[9]}`;
          } else if (c.length === 11) {
            formatted_number = `${c[0]} (${c[1]}${c[2]}${c[3]}) ${c[4]}${c[5]}${c[6]} - ${c[7]}${c[8]}${c[9]}${c[10]}`;
          }
          scope === "student"
            ? (phoneInputStudent.value = formatted_number)
            : (phoneInputParent.value = formatted_number);
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    };

    const phoneInputStudent = ref(null);
    const phoneInputParent = ref(null);

    const phoneValidationStudent = (value) => {
      return phoneValidation(value, "student");
    };

    const phoneValidationParent = (value) => {
      return phoneValidation(value, "parent");
    };

    const postalCodeValidation = (value) => {
      // This is an optional field so don't error if empty
      if (!value) {
        return true;
      }

      return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    }

    const collegeValidation = () => !!acSettings.value["college"].itemSelected;

    defineRule("required", required);
    defineRule("email", email);
    defineRule("phoneValidationStudent", phoneValidationStudent);
    defineRule("phoneValidationParent", phoneValidationParent);
    defineRule("postalCodeValidation", postalCodeValidation);
    defineRule("college", collegeValidation);
    defineRule("digits", digits);
    defineRule("max", max);

    const validationRules = ref({
      firstName: "required|max:75",
      lastName: "required|max:75",
      email: "required|email|max:75",
      phone: "required|phoneValidationStudent",
      postalCode: "postalCodeValidation",
      gradYear: "required|digits:4",
      college: "required|college",
      parentFirstName: "",
      parentLastName: "",
      parentEmail: "",
      parentPhone: "",
      hearAboutUs: "required|max:75"
    });

    const updateParentVisbility = (show) => {
      if (show) {
        validationRules.value.parentFirstName = "required|max:75";
        validationRules.value.parentLastName = "required|max:75";
        validationRules.value.parentPhone = "phoneValidationParent";
        validationRules.value.parentEmail = "required|email|max:75";
        isParent.value = true;
      } else {
        validationRules.value.parentFirstName = "";
        validationRules.value.parentLastName = "";
        validationRules.value.parentPhone = "";
        validationRules.value.parentEmail = "";
        isParent.value = false;
      }
    };

    const formSpreeEndpoint = "https://formspree.io/f/mqazewwb";

    const sendForm = (values) => {
      if (formIsSubmitting.value === false) {
        formIsSubmitting.value = true;

        // Applies the address data (optional)
        const addressData = {
          street1: values.address,
          street2: values.address2,
          city: values.city,
          stateAbbreviation: values.state,
          postalCode: values.postalCode
        }

        // Social media urls (optional)
        // If we add many more might be a good idea to do a named group of inputs and just grab them all at once.
        const socialMediaAccounts = [
          values.instagram || null,
          values.snapchat || null,
        ].filter((el) => {
          return el !== null;
        });

        let studentData = {
          firstName: values.firstName,
          lastName: values.lastName,
          emails: [values.email],
          addresses: [addressData],
          highSchoolGraduationYear: Number(values.gradYear),
          affiliations: [],
          socialMediaAccounts,
          notes: "Where did you hear about us? " + values.hearAboutUs
        };

        // phone number
        if (values.phone && values.phone.length) {
          studentData.phoneNumbers = [
            {
              number: values.phone,
              primary: true,
            },
          ];
        }

        // highSchool affiliation (optional)
        let role = "";

        if (acSettings?.value && acSettings.value["highSchool"].itemSelected !== null) {
          if (
            acSettings.value[
              "highSchool"
            ].itemSelected.entityType.toLowerCase() === "parish"
          ) {
            role = "Parishioner";
          } else if (acSettings.value[
              "highSchool"
            ].itemSelected.entityType.toLowerCase() === "highSchool") {
            role = "Student";
          }

          if (role) {
            studentData.affiliations.push({
              entityId: acSettings.value["highSchool"].itemSelected.id,
              roleName: role,
              status: "ACTIVE",
            });
            // diocese affiliation
            // based on high school or parish
            if (
              "dioceseId" in acSettings.value["highSchool"].itemSelected &&
              acSettings.value["highSchool"].itemSelected.dioceseId !== null
            ) {
              studentData.affiliations.push({
                entityId: acSettings.value["highSchool"].itemSelected.dioceseId,
                roleName: "Parishioner",
                status: "ACTIVE",
              });
            }
          }
        }

        // college affiliation (optional)
        if (acSettings?.value && acSettings.value["college"].itemSelected !== null) {
          studentData.affiliations.push({
            entityId: acSettings.value["college"].itemSelected.id,
            roleName: "Student",
            status: "ACTIVE",
          });
        }

        (async () => {
          try {
            const studentResponse = await http.post("student", studentData);

            if (
              isParent.value &&
              "data" in studentResponse &&
              "id" in studentResponse.data
            ) {
              // parent
              // API POST to parent endpoint w/ reference to student)

              let parentData = {
                firstName: values.parentFirstName,
                lastName: values.parentLastName,
                studentIds: [studentResponse.data.id],
              };

              // phone number (optional)
              if (values.parentPhone && values.parentPhone.length) {
                parentData.phoneNumbers = [
                  {
                    number: values.parentPhone,
                    primary: true,
                  },
                ];
              }

              // email (optional)
              if (values.parentEmail && values.parentEmail.length) {
                parentData.emails = [values.parentEmail];
              }

              if (acSettings.value["highSchool"].itemSelected !== null) {
                // if student selects an ENTITY_TYPE = Parish for high school, then create affiliation for parent to that parish
                if (
                  acSettings.value[
                    "highSchool"
                  ].itemSelected.entityType.toLowerCase() === "parish"
                ) {
                  let parentAffiliations = [
                    {
                      entityId: acSettings.value["highSchool"].itemSelected.id,
                      roleName: "Parishioner",
                      status: "ACTIVE",
                    },
                  ];
                  parentData.affiliations = parentAffiliations;
                }
              }

              await http.post("parent", parentData);
              // const parentResponse = await http.post('parent', parentData);
            }

            // Send to Thank you page after successful sign up
            router.push({
              name: "thankYou",
              params: {
                parent: isParent.value,
                studentFirstName: values.firstName,
              },
            });

            // Send data to FormSpree
            const formSpreeData = new FormData();
            const parentData = new Set([
              "parentFirstName",
              "parentLastName",
              "parentEmail",
              "parentPhone",
            ]);

            Object.entries(values).forEach(([key, value]) => {
              if (!parentData.has(key) || isParent) {
                formSpreeData.append(key, value);
              }
            });

            await fetch(formSpreeEndpoint, {
              method: "POST",
              body: formSpreeData,
              headers: {
                Accept: "application/json",
              },
            });

          } catch (error) {
            window.alert("Sorry, an error has occurred. Please try again.");
            formIsSubmitting.value = false;
            console.log(error);
          }
        })();
      }
    };

    const closeAutocomplete = (scope) => {
      if (scope) {
        acSettings.value[scope].itemsList = [];
      } else {
        acSettings.value.highSchool.itemsList = [];
        acSettings.value.college.itemsList = [];
      }
    };

    const formWrapperClick = () => {
      closeAutocomplete();
    };

    const currentYear = (new Date()).getFullYear();

    return {
      isParent,
      sendForm,
      validationRules,
      updateParentVisbility,
      unitedStatesList,
      autocomplete,
      acSettings,
      acFocus,
      chooseItem,
      formWrapperClick,
      closeAutocomplete,
      secondaryLineValidation,
      phoneInputStudent,
      phoneInputParent,
      formIsSubmitting,
      currentYear
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/form.scss";
</style>
