<template>

  <div class="thank-you-wrapper">

    <div class="headings">
      <div class="container">
        <h1><span>Success!</span> Thank you for signing up.</h1>
        <h2>We have sent your information to the Campus Minister, so they can reach out to you.</h2>
      </div>
    </div>

    <div class="footer-links">
        <div class="link">
          <a href="https://www.newmanministry.com/">NewmanMinistry.com</a>
        </div>
        <div class="link" v-if="parent === 'true'">
          <span class="divider">|</span>
          <router-link :to="{ name: 'home' }">Sign up another student</router-link>
        </div>
      </div>

  </div>

</template>

<script>
// import { ref } from 'vue'

export default {
  name: 'ThankYou',
  components: {

  },
  props: ['parent','studentFirstName'],
  setup() {

    return {
      
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/thankyou.scss";
</style>
